
<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="cards">
          <form id="createSalesInvoices" @submit="createSalesInvoices">
            <CCardHeader>
              <CCol col="6" class="styleHeader">
                {{ $t("message.createPaymentInvoices") }}</CCol
              >
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <div class="required form-group">
                    <label>
                      {{ $t("message.customer") }}
                    </label>
                    <CButton
                      type="button"
                      @click="showModal = true"
                      class="copy add-btn"
                    >
                      +
                    </CButton>
                     <multiselect
                        id="customer"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="selectCustomer"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label>
                      {{ $t("message.balance") }}
                    </label>
                    <br />
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :placeholder="$t('message.balance')"
                      name="balance"
                      v-model="balance"
                      required
                    />
                  </div>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol sm="4">
                  <CInput
                    :label="$t('message.invoicePrice')"
                    id="invoice-price"
                    v-model="invoicePrice"
                    class="required"
                    :placeholder="$t('message.invoicePrice')"
                    :invalidFeedback="errors.invoicePrice"
                    required
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle" />
                {{ $t("message.save") }}</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger">
                <CIcon name="cil-ban" /> {{ $t("message.clear") }}
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
     <Modal v-model="showModal">
      <NewCustomer />
    </Modal>
  </div>
</template>

<script>
import swal from 'sweetalert'
import NewCustomer from '@/components/NewCustomer.vue'
import $ from 'jquery'
export default {
  name: 'createSalesInvoices',
  data: function () {
    return {
      showModal: false,
      invoicePrice: '',
      customer: '',
      customers: [],
      customerPhone: '',
      balance: '',
      name: '',
      true: '',
      errors: []
    }
  },
  components: {
    NewCustomer
  },
  created () {
    console.log(this.successResponse)
    const customers = []
    this.$http.get(`${this.$hostUrl}customers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        customers.push({
          id: value.id,
          name: value.name + ' - ' + value.phone,
          phone: value.phone,
          balance: value.balance
        })
      })
      this.customers = customers
    })
  },
  methods: {
    selectCustomer (event) {
      const current = this
      current.name = event.name
      current.balance = event.balance
      console.log('event', event)
      current.customerPhone = event.phone
    },
    search () {
      console.log('this.customerPhone', this.customerPhone)
      const current = this
      current.balance = ''
      if (this.customerPhone.length === 10) {
        this.$http
          .get(`${this.$hostUrl}customers-search?phone=${this.customerPhone}`)
          .then((response) => {
            current.customer = response.data.data
            current.balance = current.customer.balance
            current.name = current.customer.name
          })
      }
    },
    async createSalesInvoices (e) {
      e.preventDefault()
      if (this.customerPhone.length !== 10) {
        swal(this.$t('message.errorCustomerPhone'))
        return
      }

      const formData = new FormData()
      formData.append('price_paid', this.invoicePrice)
      formData.append('customer', JSON.stringify(this.customer))
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'sale-invoices',
        '/payments'
      )
    }
  },
  watch: {
    successResponse () {
      console.log(this.successResponse, 'watch')
    }
  }
}
</script>
<style scoped>
.order {
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add {
  background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
.line {
  width: 80px;
  height: 4px;
  background: #2a3647;
  margin-bottom: 32px;
}
</style>
